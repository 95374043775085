import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b73e1bc8 = () => interopDefault(import('../pages/add-line-notify.vue' /* webpackChunkName: "pages/add-line-notify" */))
const _f51b31e6 = () => interopDefault(import('../pages/bakery-box-with-logo.vue' /* webpackChunkName: "pages/bakery-box-with-logo" */))
const _80d5d74c = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _b81d3cb8 = () => interopDefault(import('../pages/callback-line-notify.vue' /* webpackChunkName: "pages/callback-line-notify" */))
const _2753a391 = () => interopDefault(import('../pages/category-by-business.vue' /* webpackChunkName: "pages/category-by-business" */))
const _1cb34a30 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _0ae8fbec = () => interopDefault(import('../pages/clear-cart.vue' /* webpackChunkName: "pages/clear-cart" */))
const _50290e3e = () => interopDefault(import('../pages/commerce-packaging/index.vue' /* webpackChunkName: "pages/commerce-packaging/index" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _2fb5509a = () => interopDefault(import('../pages/cookies-policy.vue' /* webpackChunkName: "pages/cookies-policy" */))
const _10884d6b = () => interopDefault(import('../pages/corporate.vue' /* webpackChunkName: "pages/corporate" */))
const _4a8574af = () => interopDefault(import('../pages/customer-survey.vue' /* webpackChunkName: "pages/customer-survey" */))
const _794effcc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _2312c36a = () => interopDefault(import('../pages/enterprise.vue' /* webpackChunkName: "pages/enterprise" */))
const _199ef1cf = () => interopDefault(import('../pages/health_check.vue' /* webpackChunkName: "pages/health_check" */))
const _6346d9c1 = () => interopDefault(import('../pages/health-check.vue' /* webpackChunkName: "pages/health-check" */))
const _61cb3f46 = () => interopDefault(import('../pages/login-page.vue' /* webpackChunkName: "pages/login-page" */))
const _d549b25e = () => interopDefault(import('../pages/logo-printing-all.vue' /* webpackChunkName: "pages/logo-printing-all" */))
const _5ffe4b8a = () => interopDefault(import('../pages/logout-makro.vue' /* webpackChunkName: "pages/logout-makro" */))
const _5b446a70 = () => interopDefault(import('../pages/logout-store.vue' /* webpackChunkName: "pages/logout-store" */))
const _6790850c = () => interopDefault(import('../pages/logout-wongnai.vue' /* webpackChunkName: "pages/logout-wongnai" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6d4a60cc = () => interopDefault(import('../pages/new-design-form.vue' /* webpackChunkName: "pages/new-design-form" */))
const _cab39716 = () => interopDefault(import('../pages/new-design.vue' /* webpackChunkName: "pages/new-design" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _8572b59c = () => interopDefault(import('../pages/packaging-design.vue' /* webpackChunkName: "pages/packaging-design" */))
const _7cb02c9f = () => interopDefault(import('../pages/policy-setting.vue' /* webpackChunkName: "pages/policy-setting" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _69a54292 = () => interopDefault(import('../pages/product-management-dashboard.vue' /* webpackChunkName: "pages/product-management-dashboard" */))
const _6583a617 = () => interopDefault(import('../pages/product-return-policy.vue' /* webpackChunkName: "pages/product-return-policy" */))
const _54cde8a2 = () => interopDefault(import('../pages/products-all.vue' /* webpackChunkName: "pages/products-all" */))
const _5a5dfab7 = () => interopDefault(import('../pages/products-search.vue' /* webpackChunkName: "pages/products-search" */))
const _2ed88a1b = () => interopDefault(import('../pages/products-tag.vue' /* webpackChunkName: "pages/products-tag" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _365feaa5 = () => interopDefault(import('../pages/qt-corporate.vue' /* webpackChunkName: "pages/qt-corporate" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _02dfda38 = () => interopDefault(import('../pages/request-price-supplier.vue' /* webpackChunkName: "pages/request-price-supplier" */))
const _d6c512e2 = () => interopDefault(import('../pages/samples.vue' /* webpackChunkName: "pages/samples" */))
const _6c077412 = () => interopDefault(import('../pages/scan-qr.vue' /* webpackChunkName: "pages/scan-qr" */))
const _7f02edb6 = () => interopDefault(import('../pages/store-bangsue.vue' /* webpackChunkName: "pages/store-bangsue" */))
const _ce77be6e = () => interopDefault(import('../pages/user-crm/index.vue' /* webpackChunkName: "pages/user-crm/index" */))
const _c2ac7fb4 = () => interopDefault(import('../pages/water-bottle.vue' /* webpackChunkName: "pages/water-bottle" */))
const _f7f1355a = () => interopDefault(import('../pages/auth/facebook.vue' /* webpackChunkName: "pages/auth/facebook" */))
const _760707c0 = () => interopDefault(import('../pages/commerce-packaging/blogs/index.vue' /* webpackChunkName: "pages/commerce-packaging/blogs/index" */))
const _bfd2feec = () => interopDefault(import('../pages/commerce-packaging/checkout.vue' /* webpackChunkName: "pages/commerce-packaging/checkout" */))
const _f36e4918 = () => interopDefault(import('../pages/commerce-packaging/cookies-policy.vue' /* webpackChunkName: "pages/commerce-packaging/cookies-policy" */))
const _0ce83b5e = () => interopDefault(import('../pages/commerce-packaging/corporate.vue' /* webpackChunkName: "pages/commerce-packaging/corporate" */))
const _a747d600 = () => interopDefault(import('../pages/commerce-packaging/dashboard.vue' /* webpackChunkName: "pages/commerce-packaging/dashboard" */))
const _06896837 = () => interopDefault(import('../pages/commerce-packaging/login-page.vue' /* webpackChunkName: "pages/commerce-packaging/login-page" */))
const _306558d6 = () => interopDefault(import('../pages/commerce-packaging/logout-makro.vue' /* webpackChunkName: "pages/commerce-packaging/logout-makro" */))
const _2bab77bc = () => interopDefault(import('../pages/commerce-packaging/logout-store.vue' /* webpackChunkName: "pages/commerce-packaging/logout-store" */))
const _83b7e034 = () => interopDefault(import('../pages/commerce-packaging/logout-wongnai.vue' /* webpackChunkName: "pages/commerce-packaging/logout-wongnai" */))
const _6d5681ce = () => interopDefault(import('../pages/commerce-packaging/logout.vue' /* webpackChunkName: "pages/commerce-packaging/logout" */))
const _2daf9d9c = () => interopDefault(import('../pages/commerce-packaging/new-design-form.vue' /* webpackChunkName: "pages/commerce-packaging/new-design-form" */))
const _5bd58762 = () => interopDefault(import('../pages/commerce-packaging/new-design.vue' /* webpackChunkName: "pages/commerce-packaging/new-design" */))
const _85ba3ce8 = () => interopDefault(import('../pages/commerce-packaging/packaging-design.vue' /* webpackChunkName: "pages/commerce-packaging/packaging-design" */))
const _5978910e = () => interopDefault(import('../pages/commerce-packaging/policy-setting.vue' /* webpackChunkName: "pages/commerce-packaging/policy-setting" */))
const _48e6d4db = () => interopDefault(import('../pages/commerce-packaging/privacy-policy.vue' /* webpackChunkName: "pages/commerce-packaging/privacy-policy" */))
const _28fddc06 = () => interopDefault(import('../pages/commerce-packaging/product-return-policy.vue' /* webpackChunkName: "pages/commerce-packaging/product-return-policy" */))
const _538869c6 = () => interopDefault(import('../pages/commerce-packaging/products-search.vue' /* webpackChunkName: "pages/commerce-packaging/products-search" */))
const _38d2f2d6 = () => interopDefault(import('../pages/commerce-packaging/profile.vue' /* webpackChunkName: "pages/commerce-packaging/profile" */))
const _4e2c63ff = () => interopDefault(import('../pages/commerce-packaging/qt-corporate.vue' /* webpackChunkName: "pages/commerce-packaging/qt-corporate" */))
const _39c52932 = () => interopDefault(import('../pages/commerce-packaging/register.vue' /* webpackChunkName: "pages/commerce-packaging/register" */))
const _39a5e616 = () => interopDefault(import('../pages/commerce-packaging/samples.vue' /* webpackChunkName: "pages/commerce-packaging/samples" */))
const _2150a8ba = () => interopDefault(import('../pages/commerce-packaging/user-crm/index.vue' /* webpackChunkName: "pages/commerce-packaging/user-crm/index" */))
const _a2dbc0ac = () => interopDefault(import('../pages/logo-printing/accessories-logo.vue' /* webpackChunkName: "pages/logo-printing/accessories-logo" */))
const _aa25785c = () => interopDefault(import('../pages/logo-printing/bag-logo.vue' /* webpackChunkName: "pages/logo-printing/bag-logo" */))
const _f77bec80 = () => interopDefault(import('../pages/logo-printing/bottle-logo.vue' /* webpackChunkName: "pages/logo-printing/bottle-logo" */))
const _7353c4a4 = () => interopDefault(import('../pages/logo-printing/bowl-logo.vue' /* webpackChunkName: "pages/logo-printing/bowl-logo" */))
const _29dc4b48 = () => interopDefault(import('../pages/logo-printing/cup-logo.vue' /* webpackChunkName: "pages/logo-printing/cup-logo" */))
const _53ef9fe0 = () => interopDefault(import('../pages/logo-printing/custombox-logo.vue' /* webpackChunkName: "pages/logo-printing/custombox-logo" */))
const _e2c6ce36 = () => interopDefault(import('../pages/logo-printing/flexible-packaging-logo.vue' /* webpackChunkName: "pages/logo-printing/flexible-packaging-logo" */))
const _419716b5 = () => interopDefault(import('../pages/logo-printing/food-box-list-printing.vue' /* webpackChunkName: "pages/logo-printing/food-box-list-printing" */))
const _1aff1eea = () => interopDefault(import('../pages/logo-printing/food-box-logo.vue' /* webpackChunkName: "pages/logo-printing/food-box-logo" */))
const _1506f2d4 = () => interopDefault(import('../pages/logo-printing/food-box-printing.vue' /* webpackChunkName: "pages/logo-printing/food-box-printing" */))
const _59c9c594 = () => interopDefault(import('../pages/logo-printing/food-box-set/index.vue' /* webpackChunkName: "pages/logo-printing/food-box-set/index" */))
const _683f2b8a = () => interopDefault(import('../pages/logo-printing/paper-wrap-logo.vue' /* webpackChunkName: "pages/logo-printing/paper-wrap-logo" */))
const _42c93842 = () => interopDefault(import('../pages/logo-printing/plastic-bag-logo.vue' /* webpackChunkName: "pages/logo-printing/plastic-bag-logo" */))
const _6b3c3f1a = () => interopDefault(import('../pages/logo-printing/shipping-box-with-logo.vue' /* webpackChunkName: "pages/logo-printing/shipping-box-with-logo" */))
const _424c5fbe = () => interopDefault(import('../pages/logo-printing/water-bottle.vue' /* webpackChunkName: "pages/logo-printing/water-bottle" */))
const _4e7cdb0e = () => interopDefault(import('../pages/product/bakery-dessert-packaging.vue' /* webpackChunkName: "pages/product/bakery-dessert-packaging" */))
const _c270328c = () => interopDefault(import('../pages/product/beverage-bottle.vue' /* webpackChunkName: "pages/product/beverage-bottle" */))
const _083955fa = () => interopDefault(import('../pages/product/beverage-cup.vue' /* webpackChunkName: "pages/product/beverage-cup" */))
const _6b9b42ec = () => interopDefault(import('../pages/product/bowl.vue' /* webpackChunkName: "pages/product/bowl" */))
const _18f43d50 = () => interopDefault(import('../pages/product/corrugated-box.vue' /* webpackChunkName: "pages/product/corrugated-box" */))
const _089c5c9c = () => interopDefault(import('../pages/product/eco-bag.vue' /* webpackChunkName: "pages/product/eco-bag" */))
const _5d553c0b = () => interopDefault(import('../pages/product/flexible-packaging.vue' /* webpackChunkName: "pages/product/flexible-packaging" */))
const _1c8710e6 = () => interopDefault(import('../pages/product/food-box.vue' /* webpackChunkName: "pages/product/food-box" */))
const _655d2150 = () => interopDefault(import('../pages/product/others-accessories.vue' /* webpackChunkName: "pages/product/others-accessories" */))
const _62739f2d = () => interopDefault(import('../pages/product/plastic-bug.vue' /* webpackChunkName: "pages/product/plastic-bug" */))
const _b508acb6 = () => interopDefault(import('../pages/product/sealing-machine.vue' /* webpackChunkName: "pages/product/sealing-machine" */))
const _87fa5bca = () => interopDefault(import('../pages/product/special-price.vue' /* webpackChunkName: "pages/product/special-price" */))
const _a5489278 = () => interopDefault(import('../pages/user-crm/notification.vue' /* webpackChunkName: "pages/user-crm/notification" */))
const _5d45ca3c = () => interopDefault(import('../pages/user-crm/rebound.vue' /* webpackChunkName: "pages/user-crm/rebound" */))
const _c48c0b4a = () => interopDefault(import('../pages/user-crm/rewards.vue' /* webpackChunkName: "pages/user-crm/rewards" */))
const _c760adac = () => interopDefault(import('../pages/commerce-packaging/logo-printing/corrugated-box.vue' /* webpackChunkName: "pages/commerce-packaging/logo-printing/corrugated-box" */))
const _1b033db8 = () => interopDefault(import('../pages/commerce-packaging/logo-printing/parcel-paper.vue' /* webpackChunkName: "pages/commerce-packaging/logo-printing/parcel-paper" */))
const _cf039576 = () => interopDefault(import('../pages/commerce-packaging/logo-printing/shipping-bag.vue' /* webpackChunkName: "pages/commerce-packaging/logo-printing/shipping-bag" */))
const _31dca27e = () => interopDefault(import('../pages/commerce-packaging/logo-printing/tape.vue' /* webpackChunkName: "pages/commerce-packaging/logo-printing/tape" */))
const _2d35f232 = () => interopDefault(import('../pages/commerce-packaging/product/corrugated-box.vue' /* webpackChunkName: "pages/commerce-packaging/product/corrugated-box" */))
const _304262eb = () => interopDefault(import('../pages/commerce-packaging/product/parcel-box.vue' /* webpackChunkName: "pages/commerce-packaging/product/parcel-box" */))
const _2bb882fe = () => interopDefault(import('../pages/commerce-packaging/product/parcel-cushion.vue' /* webpackChunkName: "pages/commerce-packaging/product/parcel-cushion" */))
const _1a19bf66 = () => interopDefault(import('../pages/commerce-packaging/product/shipping-bag.vue' /* webpackChunkName: "pages/commerce-packaging/product/shipping-bag" */))
const _42003e01 = () => interopDefault(import('../pages/commerce-packaging/product/special-price.vue' /* webpackChunkName: "pages/commerce-packaging/product/special-price" */))
const _3f5dd4f4 = () => interopDefault(import('../pages/commerce-packaging/product/tape.vue' /* webpackChunkName: "pages/commerce-packaging/product/tape" */))
const _335922aa = () => interopDefault(import('../pages/commerce-packaging/user-crm/notification.vue' /* webpackChunkName: "pages/commerce-packaging/user-crm/notification" */))
const _5d8d5188 = () => interopDefault(import('../pages/commerce-packaging/user-crm/rebound.vue' /* webpackChunkName: "pages/commerce-packaging/user-crm/rebound" */))
const _c4d39296 = () => interopDefault(import('../pages/commerce-packaging/user-crm/rewards.vue' /* webpackChunkName: "pages/commerce-packaging/user-crm/rewards" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7aa462bc = () => interopDefault(import('../pages/commerce-packaging/blogs/posts/_slug.vue' /* webpackChunkName: "pages/commerce-packaging/blogs/posts/_slug" */))
const _08daef16 = () => interopDefault(import('../pages/commerce-packaging/blogs/tags/_slug.vue' /* webpackChunkName: "pages/commerce-packaging/blogs/tags/_slug" */))
const _7ef913d6 = () => interopDefault(import('../pages/blogs/posts/_slug.vue' /* webpackChunkName: "pages/blogs/posts/_slug" */))
const _08feb2bc = () => interopDefault(import('../pages/blogs/tags/_slug.vue' /* webpackChunkName: "pages/blogs/tags/_slug" */))
const _722e3be1 = () => interopDefault(import('../pages/categories/by-food/_category_name.vue' /* webpackChunkName: "pages/categories/by-food/_category_name" */))
const _1bac07bc = () => interopDefault(import('../pages/categories/by-use/_category_name.vue' /* webpackChunkName: "pages/categories/by-use/_category_name" */))
const _c4cfcf62 = () => interopDefault(import('../pages/commerce-packaging/confirmpay/_orderCode.vue' /* webpackChunkName: "pages/commerce-packaging/confirmpay/_orderCode" */))
const _939f87fa = () => interopDefault(import('../pages/commerce-packaging/information/_tabActive.vue' /* webpackChunkName: "pages/commerce-packaging/information/_tabActive" */))
const _256b2667 = () => interopDefault(import('../pages/commerce-packaging/item-tracking-old/_trackingNumber.vue' /* webpackChunkName: "pages/commerce-packaging/item-tracking-old/_trackingNumber" */))
const _72c981e6 = () => interopDefault(import('../pages/commerce-packaging/item-tracking/_trackingNumber.vue' /* webpackChunkName: "pages/commerce-packaging/item-tracking/_trackingNumber" */))
const _75714421 = () => interopDefault(import('../pages/commerce-packaging/order-tracking/_orderCode.vue' /* webpackChunkName: "pages/commerce-packaging/order-tracking/_orderCode" */))
const _72c16149 = () => interopDefault(import('../pages/commerce-packaging/payment/_orderCode.vue' /* webpackChunkName: "pages/commerce-packaging/payment/_orderCode" */))
const _0ee71a3f = () => interopDefault(import('../pages/commerce-packaging/product/_productName.vue' /* webpackChunkName: "pages/commerce-packaging/product/_productName" */))
const _8920daf6 = () => interopDefault(import('../pages/commerce-packaging/thankyou-subscribe/_orderCode.vue' /* webpackChunkName: "pages/commerce-packaging/thankyou-subscribe/_orderCode" */))
const _4519b470 = () => interopDefault(import('../pages/logo-printing/custombox-logo-detail/_boxName.vue' /* webpackChunkName: "pages/logo-printing/custombox-logo-detail/_boxName" */))
const _164cc1b2 = () => interopDefault(import('../pages/logo-printing/food-box-set/_productName.vue' /* webpackChunkName: "pages/logo-printing/food-box-set/_productName" */))
const _179aac69 = () => interopDefault(import('../pages/confirmpay/_orderCode.vue' /* webpackChunkName: "pages/confirmpay/_orderCode" */))
const _d3586430 = () => interopDefault(import('../pages/e-tax/_taxInvoiceCode.vue' /* webpackChunkName: "pages/e-tax/_taxInvoiceCode" */))
const _7c802b29 = () => interopDefault(import('../pages/information/_tabActive.vue' /* webpackChunkName: "pages/information/_tabActive" */))
const _b50338fe = () => interopDefault(import('../pages/item-tracking-old/_trackingNumber.vue' /* webpackChunkName: "pages/item-tracking-old/_trackingNumber" */))
const _8485cdb2 = () => interopDefault(import('../pages/item-tracking/_trackingNumber.vue' /* webpackChunkName: "pages/item-tracking/_trackingNumber" */))
const _45783b3b = () => interopDefault(import('../pages/order-tracking/_orderCode.vue' /* webpackChunkName: "pages/order-tracking/_orderCode" */))
const _06e7c41f = () => interopDefault(import('../pages/partner-store/_store.vue' /* webpackChunkName: "pages/partner-store/_store" */))
const _e077291a = () => interopDefault(import('../pages/partners/_partner.vue' /* webpackChunkName: "pages/partners/_partner" */))
const _0dfa5922 = () => interopDefault(import('../pages/payment/_orderCode.vue' /* webpackChunkName: "pages/payment/_orderCode" */))
const _2165bcf0 = () => interopDefault(import('../pages/product-set/_productName.vue' /* webpackChunkName: "pages/product-set/_productName" */))
const _0a949ae5 = () => interopDefault(import('../pages/product/_productName.vue' /* webpackChunkName: "pages/product/_productName" */))
const _942dbe14 = () => interopDefault(import('../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _4d8d97f6 = () => interopDefault(import('../pages/store/_franchise.vue' /* webpackChunkName: "pages/store/_franchise" */))
const _9add26c2 = () => interopDefault(import('../pages/thankyou-subscribe/_orderCode.vue' /* webpackChunkName: "pages/thankyou-subscribe/_orderCode" */))
const _6765f93c = () => interopDefault(import('../pages/thankyou/_orderCode.vue' /* webpackChunkName: "pages/thankyou/_orderCode" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-line-notify/",
    component: _b73e1bc8,
    pathToRegexpOptions: {"strict":true},
    name: "add-line-notify"
  }, {
    path: "/bakery-box-with-logo/",
    component: _f51b31e6,
    pathToRegexpOptions: {"strict":true},
    name: "bakery-box-with-logo"
  }, {
    path: "/blogs/",
    component: _80d5d74c,
    pathToRegexpOptions: {"strict":true},
    name: "blogs"
  }, {
    path: "/callback-line-notify/",
    component: _b81d3cb8,
    pathToRegexpOptions: {"strict":true},
    name: "callback-line-notify"
  }, {
    path: "/category-by-business/",
    component: _2753a391,
    pathToRegexpOptions: {"strict":true},
    name: "category-by-business"
  }, {
    path: "/checkout/",
    component: _1cb34a30,
    pathToRegexpOptions: {"strict":true},
    name: "checkout"
  }, {
    path: "/clear-cart/",
    component: _0ae8fbec,
    pathToRegexpOptions: {"strict":true},
    name: "clear-cart"
  }, {
    path: "/commerce-packaging/",
    component: _50290e3e,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging"
  }, {
    path: "/contact-us/",
    component: _521bf555,
    pathToRegexpOptions: {"strict":true},
    name: "contact-us"
  }, {
    path: "/cookies-policy/",
    component: _2fb5509a,
    pathToRegexpOptions: {"strict":true},
    name: "cookies-policy"
  }, {
    path: "/corporate/",
    component: _10884d6b,
    pathToRegexpOptions: {"strict":true},
    name: "corporate"
  }, {
    path: "/customer-survey/",
    component: _4a8574af,
    pathToRegexpOptions: {"strict":true},
    name: "customer-survey"
  }, {
    path: "/dashboard/",
    component: _794effcc,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/enterprise/",
    component: _2312c36a,
    pathToRegexpOptions: {"strict":true},
    name: "enterprise"
  }, {
    path: "/health_check/",
    component: _199ef1cf,
    pathToRegexpOptions: {"strict":true},
    name: "health_check"
  }, {
    path: "/health-check/",
    component: _6346d9c1,
    pathToRegexpOptions: {"strict":true},
    name: "health-check"
  }, {
    path: "/login-page/",
    component: _61cb3f46,
    pathToRegexpOptions: {"strict":true},
    name: "login-page"
  }, {
    path: "/logo-printing-all/",
    component: _d549b25e,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-all"
  }, {
    path: "/logout-makro/",
    component: _5ffe4b8a,
    pathToRegexpOptions: {"strict":true},
    name: "logout-makro"
  }, {
    path: "/logout-store/",
    component: _5b446a70,
    pathToRegexpOptions: {"strict":true},
    name: "logout-store"
  }, {
    path: "/logout-wongnai/",
    component: _6790850c,
    pathToRegexpOptions: {"strict":true},
    name: "logout-wongnai"
  }, {
    path: "/logout/",
    component: _49c57cf4,
    pathToRegexpOptions: {"strict":true},
    name: "logout"
  }, {
    path: "/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance"
  }, {
    path: "/new-design-form/",
    component: _6d4a60cc,
    pathToRegexpOptions: {"strict":true},
    name: "new-design-form"
  }, {
    path: "/new-design/",
    component: _cab39716,
    pathToRegexpOptions: {"strict":true},
    name: "new-design"
  }, {
    path: "/news/",
    component: _076ad200,
    pathToRegexpOptions: {"strict":true},
    name: "news"
  }, {
    path: "/packaging-design/",
    component: _8572b59c,
    pathToRegexpOptions: {"strict":true},
    name: "packaging-design"
  }, {
    path: "/policy-setting/",
    component: _7cb02c9f,
    pathToRegexpOptions: {"strict":true},
    name: "policy-setting"
  }, {
    path: "/privacy-policy/",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy"
  }, {
    path: "/product-management-dashboard/",
    component: _69a54292,
    pathToRegexpOptions: {"strict":true},
    name: "product-management-dashboard"
  }, {
    path: "/product-return-policy/",
    component: _6583a617,
    pathToRegexpOptions: {"strict":true},
    name: "product-return-policy"
  }, {
    path: "/products-all/",
    component: _54cde8a2,
    pathToRegexpOptions: {"strict":true},
    name: "products-all"
  }, {
    path: "/products-search/",
    component: _5a5dfab7,
    pathToRegexpOptions: {"strict":true},
    name: "products-search"
  }, {
    path: "/products-tag/",
    component: _2ed88a1b,
    pathToRegexpOptions: {"strict":true},
    name: "products-tag"
  }, {
    path: "/profile/",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/qt-corporate/",
    component: _365feaa5,
    pathToRegexpOptions: {"strict":true},
    name: "qt-corporate"
  }, {
    path: "/register/",
    component: _5fba350d,
    pathToRegexpOptions: {"strict":true},
    name: "register"
  }, {
    path: "/request-price-supplier/",
    component: _02dfda38,
    pathToRegexpOptions: {"strict":true},
    name: "request-price-supplier"
  }, {
    path: "/samples/",
    component: _d6c512e2,
    pathToRegexpOptions: {"strict":true},
    name: "samples"
  }, {
    path: "/scan-qr/",
    component: _6c077412,
    pathToRegexpOptions: {"strict":true},
    name: "scan-qr"
  }, {
    path: "/store-bangsue/",
    component: _7f02edb6,
    pathToRegexpOptions: {"strict":true},
    name: "store-bangsue"
  }, {
    path: "/user-crm/",
    component: _ce77be6e,
    pathToRegexpOptions: {"strict":true},
    name: "user-crm"
  }, {
    path: "/water-bottle/",
    component: _c2ac7fb4,
    pathToRegexpOptions: {"strict":true},
    name: "water-bottle"
  }, {
    path: "/auth/facebook/",
    component: _f7f1355a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-facebook"
  }, {
    path: "/commerce-packaging/blogs/",
    component: _760707c0,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-blogs"
  }, {
    path: "/commerce-packaging/checkout/",
    component: _bfd2feec,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-checkout"
  }, {
    path: "/commerce-packaging/cookies-policy/",
    component: _f36e4918,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-cookies-policy"
  }, {
    path: "/commerce-packaging/corporate/",
    component: _0ce83b5e,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-corporate"
  }, {
    path: "/commerce-packaging/dashboard/",
    component: _a747d600,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-dashboard"
  }, {
    path: "/commerce-packaging/login-page/",
    component: _06896837,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-login-page"
  }, {
    path: "/commerce-packaging/logout-makro/",
    component: _306558d6,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logout-makro"
  }, {
    path: "/commerce-packaging/logout-store/",
    component: _2bab77bc,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logout-store"
  }, {
    path: "/commerce-packaging/logout-wongnai/",
    component: _83b7e034,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logout-wongnai"
  }, {
    path: "/commerce-packaging/logout/",
    component: _6d5681ce,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logout"
  }, {
    path: "/commerce-packaging/new-design-form/",
    component: _2daf9d9c,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-new-design-form"
  }, {
    path: "/commerce-packaging/new-design/",
    component: _5bd58762,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-new-design"
  }, {
    path: "/commerce-packaging/packaging-design/",
    component: _85ba3ce8,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-packaging-design"
  }, {
    path: "/commerce-packaging/policy-setting/",
    component: _5978910e,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-policy-setting"
  }, {
    path: "/commerce-packaging/privacy-policy/",
    component: _48e6d4db,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-privacy-policy"
  }, {
    path: "/commerce-packaging/product-return-policy/",
    component: _28fddc06,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-return-policy"
  }, {
    path: "/commerce-packaging/products-search/",
    component: _538869c6,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-products-search"
  }, {
    path: "/commerce-packaging/profile/",
    component: _38d2f2d6,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-profile"
  }, {
    path: "/commerce-packaging/qt-corporate/",
    component: _4e2c63ff,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-qt-corporate"
  }, {
    path: "/commerce-packaging/register/",
    component: _39c52932,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-register"
  }, {
    path: "/commerce-packaging/samples/",
    component: _39a5e616,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-samples"
  }, {
    path: "/commerce-packaging/user-crm/",
    component: _2150a8ba,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-user-crm"
  }, {
    path: "/logo-printing/accessories-logo/",
    component: _a2dbc0ac,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-accessories-logo"
  }, {
    path: "/logo-printing/bag-logo/",
    component: _aa25785c,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-bag-logo"
  }, {
    path: "/logo-printing/bottle-logo/",
    component: _f77bec80,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-bottle-logo"
  }, {
    path: "/logo-printing/bowl-logo/",
    component: _7353c4a4,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-bowl-logo"
  }, {
    path: "/logo-printing/cup-logo/",
    component: _29dc4b48,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-cup-logo"
  }, {
    path: "/logo-printing/custombox-logo/",
    component: _53ef9fe0,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-custombox-logo"
  }, {
    path: "/logo-printing/flexible-packaging-logo/",
    component: _e2c6ce36,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-flexible-packaging-logo"
  }, {
    path: "/logo-printing/food-box-list-printing/",
    component: _419716b5,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-food-box-list-printing"
  }, {
    path: "/logo-printing/food-box-logo/",
    component: _1aff1eea,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-food-box-logo"
  }, {
    path: "/logo-printing/food-box-printing/",
    component: _1506f2d4,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-food-box-printing"
  }, {
    path: "/logo-printing/food-box-set/",
    component: _59c9c594,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-food-box-set"
  }, {
    path: "/logo-printing/paper-wrap-logo/",
    component: _683f2b8a,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-paper-wrap-logo"
  }, {
    path: "/logo-printing/plastic-bag-logo/",
    component: _42c93842,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-plastic-bag-logo"
  }, {
    path: "/logo-printing/shipping-box-with-logo/",
    component: _6b3c3f1a,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-shipping-box-with-logo"
  }, {
    path: "/logo-printing/water-bottle/",
    component: _424c5fbe,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-water-bottle"
  }, {
    path: "/product/bakery-dessert-packaging/",
    component: _4e7cdb0e,
    pathToRegexpOptions: {"strict":true},
    name: "product-bakery-dessert-packaging"
  }, {
    path: "/product/beverage-bottle/",
    component: _c270328c,
    pathToRegexpOptions: {"strict":true},
    name: "product-beverage-bottle"
  }, {
    path: "/product/beverage-cup/",
    component: _083955fa,
    pathToRegexpOptions: {"strict":true},
    name: "product-beverage-cup"
  }, {
    path: "/product/bowl/",
    component: _6b9b42ec,
    pathToRegexpOptions: {"strict":true},
    name: "product-bowl"
  }, {
    path: "/product/corrugated-box/",
    component: _18f43d50,
    pathToRegexpOptions: {"strict":true},
    name: "product-corrugated-box"
  }, {
    path: "/product/eco-bag/",
    component: _089c5c9c,
    pathToRegexpOptions: {"strict":true},
    name: "product-eco-bag"
  }, {
    path: "/product/flexible-packaging/",
    component: _5d553c0b,
    pathToRegexpOptions: {"strict":true},
    name: "product-flexible-packaging"
  }, {
    path: "/product/food-box/",
    component: _1c8710e6,
    pathToRegexpOptions: {"strict":true},
    name: "product-food-box"
  }, {
    path: "/product/others-accessories/",
    component: _655d2150,
    pathToRegexpOptions: {"strict":true},
    name: "product-others-accessories"
  }, {
    path: "/product/plastic-bug/",
    component: _62739f2d,
    pathToRegexpOptions: {"strict":true},
    name: "product-plastic-bug"
  }, {
    path: "/product/sealing-machine/",
    component: _b508acb6,
    pathToRegexpOptions: {"strict":true},
    name: "product-sealing-machine"
  }, {
    path: "/product/special-price/",
    component: _87fa5bca,
    pathToRegexpOptions: {"strict":true},
    name: "product-special-price"
  }, {
    path: "/user-crm/notification/",
    component: _a5489278,
    pathToRegexpOptions: {"strict":true},
    name: "user-crm-notification"
  }, {
    path: "/user-crm/rebound/",
    component: _5d45ca3c,
    pathToRegexpOptions: {"strict":true},
    name: "user-crm-rebound"
  }, {
    path: "/user-crm/rewards/",
    component: _c48c0b4a,
    pathToRegexpOptions: {"strict":true},
    name: "user-crm-rewards"
  }, {
    path: "/commerce-packaging/logo-printing/corrugated-box/",
    component: _c760adac,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logo-printing-corrugated-box"
  }, {
    path: "/commerce-packaging/logo-printing/parcel-paper/",
    component: _1b033db8,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logo-printing-parcel-paper"
  }, {
    path: "/commerce-packaging/logo-printing/shipping-bag/",
    component: _cf039576,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logo-printing-shipping-bag"
  }, {
    path: "/commerce-packaging/logo-printing/tape/",
    component: _31dca27e,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-logo-printing-tape"
  }, {
    path: "/commerce-packaging/product/corrugated-box/",
    component: _2d35f232,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-corrugated-box"
  }, {
    path: "/commerce-packaging/product/parcel-box/",
    component: _304262eb,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-parcel-box"
  }, {
    path: "/commerce-packaging/product/parcel-cushion/",
    component: _2bb882fe,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-parcel-cushion"
  }, {
    path: "/commerce-packaging/product/shipping-bag/",
    component: _1a19bf66,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-shipping-bag"
  }, {
    path: "/commerce-packaging/product/special-price/",
    component: _42003e01,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-special-price"
  }, {
    path: "/commerce-packaging/product/tape/",
    component: _3f5dd4f4,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-tape"
  }, {
    path: "/commerce-packaging/user-crm/notification/",
    component: _335922aa,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-user-crm-notification"
  }, {
    path: "/commerce-packaging/user-crm/rebound/",
    component: _5d8d5188,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-user-crm-rebound"
  }, {
    path: "/commerce-packaging/user-crm/rewards/",
    component: _c4d39296,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-user-crm-rewards"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/commerce-packaging/blogs/posts/:slug/",
    component: _7aa462bc,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-blogs-posts-slug"
  }, {
    path: "/commerce-packaging/blogs/tags/:slug/",
    component: _08daef16,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-blogs-tags-slug"
  }, {
    path: "/blogs/posts/:slug?/",
    component: _7ef913d6,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-posts-slug"
  }, {
    path: "/blogs/tags/:slug?/",
    component: _08feb2bc,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-tags-slug"
  }, {
    path: "/categories/by-food/:category_name?/",
    component: _722e3be1,
    pathToRegexpOptions: {"strict":true},
    name: "categories-by-food-category_name"
  }, {
    path: "/categories/by-use/:category_name?/",
    component: _1bac07bc,
    pathToRegexpOptions: {"strict":true},
    name: "categories-by-use-category_name"
  }, {
    path: "/commerce-packaging/confirmpay/:orderCode/",
    component: _c4cfcf62,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-confirmpay-orderCode"
  }, {
    path: "/commerce-packaging/information/:tabActive/",
    component: _939f87fa,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-information-tabActive"
  }, {
    path: "/commerce-packaging/item-tracking-old/:trackingNumber/",
    component: _256b2667,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-item-tracking-old-trackingNumber"
  }, {
    path: "/commerce-packaging/item-tracking/:trackingNumber/",
    component: _72c981e6,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-item-tracking-trackingNumber"
  }, {
    path: "/commerce-packaging/order-tracking/:orderCode/",
    component: _75714421,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-order-tracking-orderCode"
  }, {
    path: "/commerce-packaging/payment/:orderCode/",
    component: _72c16149,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-payment-orderCode"
  }, {
    path: "/commerce-packaging/product/:productName/",
    component: _0ee71a3f,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-product-productName"
  }, {
    path: "/commerce-packaging/thankyou-subscribe/:orderCode/",
    component: _8920daf6,
    pathToRegexpOptions: {"strict":true},
    name: "commerce-packaging-thankyou-subscribe-orderCode"
  }, {
    path: "/logo-printing/custombox-logo-detail/:boxName?/",
    component: _4519b470,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-custombox-logo-detail-boxName"
  }, {
    path: "/logo-printing/food-box-set/:productName?/",
    component: _164cc1b2,
    pathToRegexpOptions: {"strict":true},
    name: "logo-printing-food-box-set-productName"
  }, {
    path: "/confirmpay/:orderCode?/",
    component: _179aac69,
    pathToRegexpOptions: {"strict":true},
    name: "confirmpay-orderCode"
  }, {
    path: "/e-tax/:taxInvoiceCode?/",
    component: _d3586430,
    pathToRegexpOptions: {"strict":true},
    name: "e-tax-taxInvoiceCode"
  }, {
    path: "/information/:tabActive?/",
    component: _7c802b29,
    pathToRegexpOptions: {"strict":true},
    name: "information-tabActive"
  }, {
    path: "/item-tracking-old/:trackingNumber?/",
    component: _b50338fe,
    pathToRegexpOptions: {"strict":true},
    name: "item-tracking-old-trackingNumber"
  }, {
    path: "/item-tracking/:trackingNumber?/",
    component: _8485cdb2,
    pathToRegexpOptions: {"strict":true},
    name: "item-tracking-trackingNumber"
  }, {
    path: "/order-tracking/:orderCode?/",
    component: _45783b3b,
    pathToRegexpOptions: {"strict":true},
    name: "order-tracking-orderCode"
  }, {
    path: "/partner-store/:store?/",
    component: _06e7c41f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-store-store"
  }, {
    path: "/partners/:partner?/",
    component: _e077291a,
    pathToRegexpOptions: {"strict":true},
    name: "partners-partner"
  }, {
    path: "/payment/:orderCode?/",
    component: _0dfa5922,
    pathToRegexpOptions: {"strict":true},
    name: "payment-orderCode"
  }, {
    path: "/product-set/:productName?/",
    component: _2165bcf0,
    pathToRegexpOptions: {"strict":true},
    name: "product-set-productName"
  }, {
    path: "/product/:productName?/",
    component: _0a949ae5,
    pathToRegexpOptions: {"strict":true},
    name: "product-productName"
  }, {
    path: "/reset-password/:token?/",
    component: _942dbe14,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password-token"
  }, {
    path: "/store/:franchise?/",
    component: _4d8d97f6,
    pathToRegexpOptions: {"strict":true},
    name: "store-franchise"
  }, {
    path: "/thankyou-subscribe/:orderCode?/",
    component: _9add26c2,
    pathToRegexpOptions: {"strict":true},
    name: "thankyou-subscribe-orderCode"
  }, {
    path: "/thankyou/:orderCode?/",
    component: _6765f93c,
    pathToRegexpOptions: {"strict":true},
    name: "thankyou-orderCode"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
